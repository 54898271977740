import usdtIcon from "./assets/tokens/usdt.png";
import w8bitIcon from "./assets/tokens/8bitchain.png";
import { IChainData } from "./constants/types";

interface Configs {
  [key: string]: IChainData;
}

// const serverUrl = "http://46.28.44.20/api";
const serverUrl = "https://probridge.finance/api";

// const Config: Configs = {
//   "97": {
//     id: 97,
//     name: "Testnet Binance Smart Chain",
//     network: "Binance-Testnet",
//     explorer_url: "https://testnet.bscscan.com",
//     RouterContract: "0xFB2E796afc9F98b4255c8C47CecdC5D997bF28d4",
//     icon: require("./assets/images/bnb.png"),
//     tokens: {
//       W8BIT: {
//         symbol: "W8BIT",
//         icon: w8bitIcon,
//         decimals: 18,
//         TokenContract: "0x934099689C104Ebd9eB283Ff799540e30d81E5ed",
//         UnderlyingContract: "0xc26eEDbAE20568522E4Eb7d2CaA6A78875dFcDEc",
//         minimumAmount: 20000,
//         fee: 3000,
//         native: false,
//       },
//       USDT: {
//         symbol: "USDT",
//         icon: usdtIcon,
//         decimals: 18,
//         TokenContract: "0xDd4B8735f58C075E7Ccd8fAA7D53B1DaFB1Dd44A",
//         UnderlyingContract: "0xCe85c3C5DF7dAF4524371B4014998052f5233e35",
//         minimumAmount: 100,
//         fee: 5,
//         native: false,
//       },
//     },
//   },
//   "11155111": {
//     id: 11155111,
//     name: "Sepolia Ethereum Chain",
//     network: "Sepolia",
//     RouterContract: "0x43EE0B49821CE8afd2F491ee076c2407038Ba080",
//     explorer_url: "https://sepolia.etherscan.io",
//     icon: require("./assets/images/eth.png"),
//     tokens: {
//       W8BIT: {
//         symbol: "W8BIT",
//         icon: w8bitIcon,
//         decimals: 18,
//         TokenContract: "0x3A36AB77B0312a85dcbA40195457dA9A3cE0BF75",
//         UnderlyingContract: "0x8452A6d76c608057C886F54F375C16A20F9dADCb",
//         minimumAmount: 20000,
//         fee: 3000,
//         native: false,
//       },
//       // USDT: {
//       //   symbol: "USDT",
//       //   icon: usdtIcon,
//       //   decimals: 18,
//       //   TokenContract: null,
//       //   UnderlyingContract: null,
//       //   minimumAmount: 20000,
//       //   fee: 3000,
//       //   native: false,
//       // },
//     },
//   },
//   "3124": {
//     id: 3124,
//     name: "8Bit Chain",
//     network: "8Bit Chain",
//     RouterContract: "0xBB5031b55Bca67FD1920404f07b0bb7e331900A5",
//     explorer_url: "https://testnet.8bitscan.app",
//     icon: require("./assets/images/8BitChain.png"),
//     tokens: {
//       W8BIT: {
//         symbol: "W8BIT",
//         icon: w8bitIcon,
//         decimals: 18,
//         TokenContract: "0x081151ea868F928a07FDcD15263568c3FD9C61De",
//         UnderlyingContract: "0xc8C2D659df958f8a596B43B46D9412cd3c490C2E",
//         minimumAmount: 20000,
//         fee: 3000,
//         native: true,
//       },
//       USDT: {
//         symbol: "USDT",
//         icon: usdtIcon,
//         decimals: 18,
//         TokenContract: "0x3A36AB77B0312a85dcbA40195457dA9A3cE0BF75",
//         UnderlyingContract: "0x0000000000000000000000000000000000000000",
//         minimumAmount: 100,
//         fee: 5,
//         native: false,
//       },
//     },
//   },
// };

// const chainConfig: Array<IChainData> = [
//  {
//     id: 97,
//     name: "Testnet Binance Smart Chain",
//     network: "Binance-Testnet",
//     explorer_url: "https://testnet.bscscan.com",
//     RouterContract: "0xFB2E796afc9F98b4255c8C47CecdC5D997bF28d4",
//     icon: require("./assets/images/bnb.png"),
//     tokens: {
//       W8BIT: {
//         symbol: "W8BIT",
//         icon: w8bitIcon,
//         decimals: 18,
//         TokenContract: "0x934099689C104Ebd9eB283Ff799540e30d81E5ed",
//         UnderlyingContract: "0xc26eEDbAE20568522E4Eb7d2CaA6A78875dFcDEc",
//         minimumAmount: 20000,
//         fee: 3000,
//         native: false,
//       },
//       USDT: {
//         symbol: "USDT",
//         icon: usdtIcon,
//         decimals: 18,
//         TokenContract: "0xDd4B8735f58C075E7Ccd8fAA7D53B1DaFB1Dd44A",
//         UnderlyingContract: "0xCe85c3C5DF7dAF4524371B4014998052f5233e35",
//         minimumAmount: 100,
//         fee: 5,
//         native: false,
//       },
//     },
//   },
//  {
//     id: 11155111,
//     name: "Sepolia Ethereum Chain",
//     network: "Sepolia",
//     RouterContract: "0x43EE0B49821CE8afd2F491ee076c2407038Ba080",
//     explorer_url: "https://sepolia.etherscan.io",
//     icon: require("./assets/images/eth.png"),
//     tokens: {
//       W8BIT: {
//         symbol: "W8BIT",
//         icon: w8bitIcon,
//         decimals: 18,
//         TokenContract: "0x3A36AB77B0312a85dcbA40195457dA9A3cE0BF75",
//         UnderlyingContract: "0x8452A6d76c608057C886F54F375C16A20F9dADCb",
//         minimumAmount: 20000,
//         fee: 3000,
//         native: false,
//       },
//       // USDT: {
//       //   symbol: "USDT",
//       //   icon: usdtIcon,
//       //   decimals: 18,
//       //   TokenContract: null,
//       //   UnderlyingContract: null,
//       //   minimumAmount: 20000,
//       //   fee: 3000,
//       //   native: false,
//       // },
//     },
//   },
//    {
//     id: 3124,
//     name: "8Bit Chain",
//     network: "8Bit Chain",
//     RouterContract: "0xBB5031b55Bca67FD1920404f07b0bb7e331900A5",
//     explorer_url: "https://testnet.8bitscan.app",
//     icon: require("./assets/images/8BitChain.png"),
//     tokens: {
//       W8BIT: {
//         symbol: "W8BIT",
//         icon: w8bitIcon,
//         decimals: 18,
//         TokenContract: "0x081151ea868F928a07FDcD15263568c3FD9C61De",
//         UnderlyingContract: "0xc8C2D659df958f8a596B43B46D9412cd3c490C2E",
//         minimumAmount: 20000,
//         fee: 3000,
//         native: true,
//       },
//       USDT: {
//         symbol: "USDT",
//         icon: usdtIcon,
//         decimals: 18,
//         TokenContract: "0x3A36AB77B0312a85dcbA40195457dA9A3cE0BF75",
//         UnderlyingContract: "0x0000000000000000000000000000000000000000",
//         minimumAmount: 100,
//         fee: 5,
//         native: false,
//       },
//     },
//   },
// ];


const Config: Configs = {
  "56": {
    id: 56,
    name: " Binance Smart Chain",
    network: "Binance",
    explorer_url: "https://bscscan.com",
    RouterContract: "0x18916bf273B031D46f0dad2c851A5B97ca829356",
    icon: require("./assets/images/bnb.png"),
    tokens: {
      W8BIT: {
        symbol: "W8BIT",
        icon: w8bitIcon,
        decimals: 18,
        TokenContract: "0x3D19Ac19F0CA3b10c696c257dc187c13907cC9cd",
        UnderlyingContract: "0xb7Ae4eA886fAcE39D47a47f46D6AfAbcaAd5AF6E",
        minimumAmount: 5000,
        fee: 500,
        native: false,
      },
      USDT: {
        symbol: "USDT",
        icon: usdtIcon,
        decimals: 18,
        TokenContract: "0xeb4B4D6931b3C129E476AeaaC275fF1EA58a8475",
        UnderlyingContract: "0x55d398326f99059fF775485246999027B3197955",
        minimumAmount: 5,
        fee: 0.5,
        native: false,
      },
    },
  },
  // "1": {
  //   id: 1,
  //   name: "Ethereum Chain",
  //   network: "Ethereum",
  //   RouterContract: "0x094c03B0dF9204C753C24B3ED0BF6AF9eecbF23F",
  //   explorer_url: "https://etherscan.io",
  //   icon: require("./assets/images/eth.png"),
  //   tokens: {
  //     W8BIT: {
  //       symbol: "W8BIT",
  //       icon: w8bitIcon,
  //       decimals: 18,
  //       TokenContract: "0x2052b7245D9Bc6Bf7357346AF96a834dAf455617",
  //       UnderlyingContract: "0xbc6378FAae98Fb2207Bb6c35c0f8Ce5846FD4C6c",
  //       minimumAmount: 20000,
  //       fee: 3000,
  //       native: false,
  //     },
  //   },
  // },
  "8088": {
    id: 8088,
    name: "8Bit Chain",
    network: "8Bit Chain",
    RouterContract: "0x866E1dc025D8249Cec8934bc3d0D428080645a56",
    explorer_url: "https://8bitscan.com/",
    icon: require("./assets/images/8BitChain.png"),
    tokens: {
      W8BIT: {
        symbol: "W8BIT",
        icon: w8bitIcon,
        decimals: 18,
        TokenContract: "0xDb66E557d109742340EAD67F1E060F8eDb28e8Be",
        UnderlyingContract: "0x0ca45e0779fae177f26f6abf459cf03d9b23d012",
        minimumAmount: 5000,
        fee: 500,
        native: true,
      },
      USDT: {
        symbol: "USDT",
        icon: usdtIcon,
        decimals: 18,
        TokenContract: "0xAD418b9A3D3D59CEc5BB7737b89cC75daFa198e5",
        UnderlyingContract: "0x0000000000000000000000000000000000000000",
        minimumAmount: 5,
        fee: 0.5,
        native: false,
      },
    },
  },
};

const chainConfig: Array<IChainData> = [
  {
    id: 56,
    name: " Binance Smart Chain",
    network: "Binance",
    explorer_url: "https://bscscan.com",
    RouterContract: "0x18916bf273B031D46f0dad2c851A5B97ca829356",
    icon: require("./assets/images/bnb.png"),
    tokens: {
      W8BIT: {
        symbol: "W8BIT",
        icon: w8bitIcon,
        decimals: 18,
        TokenContract: "0x3D19Ac19F0CA3b10c696c257dc187c13907cC9cd",
        UnderlyingContract: "0xb7Ae4eA886fAcE39D47a47f46D6AfAbcaAd5AF6E",
        minimumAmount: 5000,
        fee: 500,
        native: false,
      },
      USDT: {
        symbol: "USDT",
        icon: usdtIcon,
        decimals: 18,
        TokenContract: "0xeb4B4D6931b3C129E476AeaaC275fF1EA58a8475",
        UnderlyingContract: "0x55d398326f99059fF775485246999027B3197955",
        minimumAmount: 5,
        fee: 0.5,
        native: false,
      },
    },
  },
  {
    id: 8088,
    name: "8Bit Chain",
    network: "8Bit Chain",
    RouterContract: "0x866E1dc025D8249Cec8934bc3d0D428080645a56",
    explorer_url: "https://8bitscan.com/",
    icon: require("./assets/images/8BitChain.png"),
    tokens: {
      W8BIT: {
        symbol: "W8BIT",
        icon: w8bitIcon,
        decimals: 18,
        TokenContract: "0xDb66E557d109742340EAD67F1E060F8eDb28e8Be",
        UnderlyingContract: "0x0ca45e0779fae177f26f6abf459cf03d9b23d012",
        minimumAmount: 5000,
        fee: 500,
        native: true,
      },
      USDT: {
        symbol: "USDT",
        icon: usdtIcon,
        decimals: 18,
        TokenContract: "0xAD418b9A3D3D59CEc5BB7737b89cC75daFa198e5",
        UnderlyingContract: "0x0000000000000000000000000000000000000000",
        minimumAmount: 5,
        fee: 0.5,
        native: false,
      },
    },
  },
  // {
  //   id: 1,
  //   name: "Ethereum Chain",
  //   network: "Ethereum",
  //   RouterContract: "0x094c03B0dF9204C753C24B3ED0BF6AF9eecbF23F",
  //   explorer_url: "https://etherscan.io",
  //   icon: require("./assets/images/eth.png"),
  //   tokens: {
  //     W8BIT: {
  //       symbol: "W8BIT",
  //       icon: w8bitIcon,
  //       decimals: 18,
  //       TokenContract: "0x2052b7245D9Bc6Bf7357346AF96a834dAf455617",
  //       UnderlyingContract: "0xbc6378FAae98Fb2207Bb6c35c0f8Ce5846FD4C6c",
  //       minimumAmount: 20000,
  //       fee: 3000,
  //       native: false,
  //     },
  //   },
  // },
];

export { Config, chainConfig, serverUrl };
