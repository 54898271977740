import React from "react";
import Explore from "../../components/Explore";


const Home: React.FC = () => {
  return (
    <div className="pad">
      <Explore />
     
    </div>
  );
};

export default Home;
