import React, { useState, useEffect, useMemo, useRef } from "react";
import bg from "./background.mp4";
import Rotate from "../../assets/images/rotate.png";
import "./Hero.scss";
import { useTransactionStore } from "../../store/transactionStore";
import Dropdown from "../Dropdown";
import Balance from "../Balance";
import {
  useAccount,
  useContractRead,
  usePrepareContractWrite,
  useNetwork,
  useSwitchNetwork,
  useWaitForTransaction,
  useContractWrite,
  useBalance,
  useChainId,
} from "wagmi";
import { utils } from "ethers";
import { formatAddress } from "../../utils/Connectors/formatters";
import { useWeb3Modal } from "@web3modal/react";
import { Config, chainConfig, serverUrl } from "../../config";
import tokenABI from "../../utils/abi/tokenABI.json";
import routerABI from "../../utils/abi/routerABI.json";
import { IChainData, IToken } from "../../constants/types";
import SelectTokenDropdown from "../SelectTokenDropdown";
import { isTestnet } from "../../wagmi";

const sleep = (ms = 1000) => new Promise((resolve) => setTimeout(resolve, ms));

const Hero: React.FC = () => {
  const [tokenAmt, setTokenAmt] = useState(0);
  const { chain } = useNetwork();
  const chainId = useChainId();
  const {
    isLoading: switchNetworkLoading,
    data: chainDataChanged,
    switchNetwork,
    switchNetworkAsync,
  } = useSwitchNetwork();
  const [selectedSourceChain, setSelectedSourceChain] = useState<IChainData>();
  const [selectedDestinationChain, setSelectedDestinationChain] = useState<IChainData | undefined>(
    chainConfig.find((f) => f.id === 8088)
  );
  const [isFocused, setIsFocused] = useState(false);
  const setTransactionStatus = useTransactionStore((store) => store.setTransactionStatus);
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  const [isApproved, setIsApproved] = useState(false);
  const [isCached, setIsCached] = useState(false);
  const tempSourceChainId = useRef<number>(0);
  const tempDestinationChainId = useRef<number>(0);

  const [selectedToken, setSelectedToken] = useState<IToken>();

  useEffect(() => {
    if (!chainDataChanged) return;

    setSelectedSourceChain(chainConfig.find((f) => f.id === chainDataChanged.id));
    // const token = defaultTokens[chainDataChanged.id as keyof typeof defaultTokens].tokens.find(
    //   (f) => f.symbol === selectedToken?.symbol || "W8BIT"
    // );
    const token = Config[chainDataChanged.id].tokens[selectedToken?.symbol || "W8BIT"];
    if (token) {
      setSelectedToken(token);
    }

    if (tempDestinationChainId.current > 0) {
      setSelectedDestinationChain(chainConfig.find((f) => f.id === tempDestinationChainId.current));
    }
  }, [chainDataChanged]);

  useEffect(() => {
    setSelectedSourceChain(chainConfig.find((f) => f.id === chainId));
    // const token = defaultTokens[chainId as keyof typeof defaultTokens].tokens.find(
    //   (f) => f.symbol === (selectedToken?.symbol || "W8BIT")
    // );
    const token = Config[chainId].tokens[selectedToken?.symbol || "W8BIT"];
    console.log(token);
    if (token) {
      setSelectedToken(token);
    }
  }, [chainId]);

  //Get Token Balance

  const {
    data: token1,
    isLoading: token1Loding,
    refetch: refetchBalance,
  } = useContractRead({
    address: (selectedToken?.UnderlyingContract==='0x0000000000000000000000000000000000000000'?selectedToken?.TokenContract:selectedToken?.UnderlyingContract) as any,
    abi: tokenABI,
    chainId: chainId,
    functionName: "balanceOf",
    args: [address],
  });

  const { data: tokenBalanceEth } = useBalance({
    address: address,
    chainId: chainId,
  });
  // Get Allowance

  const { data: tokenAllowance, isLoading: tokenAllowanceLoding } = useContractRead({
    address: (selectedToken?.UnderlyingContract==='0x0000000000000000000000000000000000000000'?selectedToken?.TokenContract:selectedToken?.UnderlyingContract) as any,
    abi: tokenABI,
    functionName: "allowance",
    args: [address, selectedSourceChain?.RouterContract],
  });

  const { config: approveConfig } = usePrepareContractWrite({
    address: (selectedToken?.UnderlyingContract==='0x0000000000000000000000000000000000000000'?selectedToken?.TokenContract:selectedToken?.UnderlyingContract) as any,
    abi: tokenABI,
    functionName: "approve",
    args: [
      selectedSourceChain?.RouterContract,
      (tokenAmt * 1e18).toLocaleString("fullwide", { useGrouping: false }),
    ],
  });

  const {
    data: approveData,
    write: approve,
    isLoading: isApproveLoading,
    isError: isApproveError,
  } = useContractWrite(approveConfig);

  const { isSuccess: isApproveSuccess } = useWaitForTransaction({
    hash: approveData?.hash,
  });

  const {
    data: swapData,
    write: swap,
    reset,
    isError: isSwapError,
    isLoading: isSwapLoading,
  } = useContractWrite({
    address: selectedSourceChain?.RouterContract as `0x${string}`,
    abi: routerABI,
    functionName: selectedToken?.UnderlyingContract==='0x0000000000000000000000000000000000000000'?'SwapOut':"SwapOutUnderlying",
    mode: "recklesslyUnprepared",
  });

  const {
    data: swapETHData,
    write: swapETH,
    reset: resetETH,
    isError: isSwapETHError,
    isLoading: isSwapETHLoading,
  } = useContractWrite({
    address: selectedSourceChain?.RouterContract as `0x${string}`,
    abi: routerABI,
    functionName: "SwapETHOutUnderlying",
    mode: "recklesslyUnprepared",
  });

  const { isSuccess: isSwapSuccess } = useWaitForTransaction({
    hash: swapData?.hash,
  });
  const { isSuccess: isSwapETHSuccess } = useWaitForTransaction({
    hash: swapETHData?.hash,
  });

  useEffect(() => {
    if (isSwapLoading) {
      setTransactionStatus({
        status: "PENDING",
        title: "Transfer",
        message: "Transaction is on pending. please wait",
      });
    }
    if (isSwapError) {
      setTransactionStatus({
        status: "ERROR",
        title: "Transfer",
        message: "Error while transferring.please try again",
      });

      setTimeout(() => {
        setTransactionStatus(null);
      }, 3000);
    }
    if (isSwapSuccess) {
      // swapData?.wait();
      // console.log(chain?.id, swapData?.hash);
      let clock: any;
      clearInterval(clock);
      clock = setInterval(() => {
        fetch(`${serverUrl}/tx?chainId=${chain?.id}&txHash=${swapData?.hash}`)
          .then((d) => d.json())
          .then((res) => {
            // console.log("res:", res);
            if (res.data?.status === 1) {
              clearInterval(clock);
              setTransactionStatus({
                status: "SUCCESS",
                title: "Transfer",
                message: "Transaction confirmed",
              });
            } else if (res.data?.status === 0) {
              setTransactionStatus({
                status: "PENDING",
                title: "Transfer",
                message: "Your Transaction is Procesing by Validator. please wait..",
              });
            } else {
              setTransactionStatus({
                status: "PENDING",
                title: "Transfer",
                message:
                  "Your Transaction is being processced and under indexing , Wait for 2 Mins",
              });
            }
          })
          .catch((e) => {
            clearInterval(clock);
            console.log("Error:", e);
          });
      }, 20000);

      setTimeout(() => {
        setTransactionStatus(null);
      }, 3000);
    }
  }, [
    isSwapLoading,
    isSwapETHLoading,
    isSwapETHError,
    isSwapError,
    isSwapSuccess,
    isSwapETHSuccess,
    setTransactionStatus,
    chain?.id,
    swapData?.hash,
  ]);

  useEffect(() => {
    if (isApproveLoading) {
      setTransactionStatus({
        status: "PENDING",
        title: "Approve",
        message: "Transaction is on pending. please wait",
      });
    }
  }, [isApproveLoading]);

  useEffect(() => {
    if (isApproveError) {
      setTransactionStatus({
        status: "ERROR",
        title: "Approve",
        message: "Error while transferring.please try again",
      });

      setTimeout(() => {
        setTransactionStatus(null);
      }, 3000);
    }
  }, [isApproveError]);

  useEffect(() => {
    if (isApproveSuccess) {
      setTransactionStatus({
        status: "SUCCESS",
        title: "Approve",
        message: "Token Apporved Click on Transfer button to get your Token completely",
      });
      setIsCached(true);
      setIsApproved(true);
      setTimeout(() => {
        setIsApproved(true);
        refetchBalance();
        reset();
        const tempAmt = tokenAmt;
        setTokenAmt((s) => s - s);
        setTokenAmt((s) => s + tempAmt);
        setTransactionStatus(null);
      }, 3000);
    }
  }, [isApproveSuccess, setTransactionStatus, refetchBalance, reset]);

  useEffect(() => {
    if (isSwapETHLoading) {
      setTransactionStatus({
        status: "PENDING",
        title: "Transfer",
        message: "Transaction is on pending. please wait for 2 mins",
      });
    }
  }, [isSwapETHLoading]);

  useEffect(() => {
    if (isSwapETHError) {
      setTransactionStatus({
        status: "ERROR",
        title: "Transfer",
        message: "Error while transferring.please try again",
      });

      setTimeout(() => {
        setTransactionStatus(null);
      }, 3000);
    }
  }, [isSwapETHError]);

  useEffect(() => {
    if (isSwapETHSuccess) {
      // swapData?.wait();
      // console.log(chain?.id, swapETHData?.hash);
      let clock: any;
      clearInterval(clock);
      clock = setInterval(() => {
        fetch(`${serverUrl}/tx?chainId=${chain?.id}&txHash=${swapETHData?.hash}`)
          .then((d) => d.json())
          .then((res) => {
            // console.log("res:", res);
            if (res.data?.status === 1) {
              clearInterval(clock);
              setTransactionStatus({
                status: "SUCCESS",
                title: "Transfer",
                message: "Transaction confirmed",
              });
            } else if (res.data?.status === 0) {
              setTransactionStatus({
                status: "PENDING",
                title: "Transfer",
                message: "Your Transaction is Procesing by Validator. please wait..",
              });
            } else {
              setTransactionStatus({
                status: "PENDING",
                title: "Transfer",
                message:
                  "Your Transaction is being processced and under indexing , Wait for 2 Mins",
              });
            }
          })
          .catch((e) => {
            clearInterval(clock);
            console.log("Error:", e);
          });
      }, 20000);

      setTimeout(() => {
        setTransactionStatus(null);
      }, 3000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSwapETHSuccess, setTransactionStatus]);

  useEffect(() => {
    if (!isCached) {
      if (Number(tokenAllowance) / 1e18 > 0 && Number(tokenAllowance) / 1e18 >= tokenAmt) {
        setIsApproved(true);
      } else {
        setIsApproved(false);
      }
    }
  }, [tokenAmt, tokenAllowance, tokenAllowanceLoding, isCached]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleRotate = async () => {
    try {
      await switchNetworkAsync?.(selectedDestinationChain?.id);
    } catch (error) {
      console.log(error);
    }

    tempSourceChainId.current = selectedDestinationChain?.id || 0;
    tempDestinationChainId.current = selectedSourceChain?.id || 0;
    // setSourceChain(destChainData?.network);
    // setDestinationChain(sourceChainData?.network);
    // Switching network to destination chain
  };

  // useEffect(() => {
  //   const data = chainList.find((f) => f.chainId === chainId);
  //   if (data) {
  //     setSourceChain(data.name);
  //     setSelectedSourceChain(data);
  //   }

  //   // console.log(tempSourceChain.current);
  //   // console.log(tempDestinationChain.current);

  //   if (tempSourceChain.current) {
  //     const tempSourceChainList = chainList.find((f) => f.name === tempSourceChain.current);
  //     setDestinationChain(tempSourceChainList?.name || "");
  //     setSelectedDestinationChain(tempSourceChainList);
  //     tempSourceChain.current = null;
  //   }

  //   if (tempDestinationChain.current) {
  //     const tempDestinationChainList = chainList.find(
  //       (f) => f.name === tempDestinationChain.current
  //     );
  //     setSourceChain(tempDestinationChainList?.name || "");
  //     setSelectedSourceChain(tempDestinationChainList);
  //     tempDestinationChain.current = null;
  //   }
  // }, [chainId]);

  const handleSelectSourceChain = (selectedChain: number) => {
    const data = chainConfig.find((f) => f.id === selectedChain);
    const data2 = chainConfig.find((f) => f.id === 56);
    const data3 = chainConfig.find((f) => f.id === 8088);

    if (selectedChain === 8088) {
      setSelectedDestinationChain(data2);
    } else {
      setSelectedDestinationChain(data3);
    }
    if (data) {
      switchNetwork?.(data.id);
    }

    // Also, handleRotate() shouldn't be called here because it will rotate the chains unnecessarily
  };

  const handleSelectDestinationChain = (selectedChain: number) => {
    console.log("changing Destination", selectedChain);
    const data = chainConfig.find((f) => f.id === selectedChain);
    const data2 = chainConfig.find((f) => f.id === 56);
    const data3 = chainConfig.find((f) => f.id === 8088);
    
    if (selectedChain === 8088) {
        if (data2) {
            switchNetwork?.(data2.id);
        } else {
            console.error("Network with id 56 not found");
        }
    } else {
        if (data3) {
            switchNetwork?.(data3.id);
        } else {
            console.error("Network with id 8088 not found");
        }
    }
    
    if (data) {
        setSelectedDestinationChain(data);
    } else {
        console.error(`Network with id ${selectedChain} not found`);
    }
};


  const handleApprove = async () => {
    approve?.();

    setTransactionStatus({
      status: "PENDING",
      title: "Approve Token",
      message: "Your transaction is processing. Please wait!",
    });

    await sleep(3000);

    setTransactionStatus({
      status: "SUCCESS",
      title: "Approve Token",
      message: "Your transaction is completed successfully.",
    });

    await sleep(3000);

    setTransactionStatus({
      status: "ERROR",
      title: "Approve Token",
      message: "Your transaction has failed. Please try again later.",
    });

    await sleep(3000);

    setTransactionStatus(null);
  };

  const handleTransfer = async () => {
    setTransactionStatus({
      status: "PENDING",
      title: "Transfer",
      message: "Your transaction is processing. Please wait!",
    });

    console.log("active:", selectedToken?.symbol);
    swap({
      recklesslySetUnpreparedArgs: [
        selectedSourceChain?.tokens[selectedToken?.symbol as any].TokenContract,
        address,
        (tokenAmt * 1e18).toLocaleString("fullwide", { useGrouping: false }),
        selectedDestinationChain?.id,
      ],
      // recklesslySetUnpreparedOverrides:{
      //   value: sourceChainData?.nativeBridge?(tokenAmt * 1e18):0
      // }
    });
  };

  const handleETHTransfer = async () => {
    // console.log("natice:",sourceChainData.native)
    setTransactionStatus({
      status: "PENDING",
      title: "Transfer",
      message: "Your transaction is processing. Please wait!",
    });
    console.log("acnativeBridgetive:", selectedSourceChain?.tokens[selectedToken?.symbol as any].TokenContract, chainId, address);
    swapETH({
      recklesslySetUnpreparedArgs: [
        selectedSourceChain?.tokens[selectedToken?.symbol as any].TokenContract,
        address,
        selectedDestinationChain?.id,
      ],
      recklesslySetUnpreparedOverrides: {
        value: utils.parseUnits(String(tokenAmt), 18),
      },
    });
  };

  return (
    <div className="hero-wrapper">
      <div className="mx">
        <div className="box-wrapper">
          <video autoPlay loop muted className="video-background">
            <source src={bg} type="video/mp4" />
          </video>
        </div>
        <div className="maindiv">
          <h1 className="innerhead">
            Seamlessly Connecting BSC <br />
            and <span style={{ color: "#ececec" }}>8Bit Chain</span>
          </h1>
          <div className="fullset">
            <div className="fullset1">
              <div className="fullset2">
                <div className="numbers">1</div>
                <p className="confirm">Source Chain</p>
                <hr />
                <div className="chainmain">
                  <div className="chainimg">
                    <img src={selectedSourceChain?.icon} alt={selectedSourceChain?.name} />
                  </div>
                </div>
                <Dropdown
                  options={chainConfig
                    .filter((f) => {
                      if (selectedToken?.symbol === "USDT") {
                        return f.network !== "Ethereum";
                      }
                      return true;
                    })
                    .map((m) => ({ name: m.name, value: m.id.toString() }))}
                  value={selectedSourceChain?.name?.toString() || ""}
                  onChange={(value) => handleSelectSourceChain(Number(value))}
                />
                {address ? (
                  <button className="proceed" style={{ marginTop: "20px" }} onClick={() => open()}>
                    Connected To {formatAddress(address)}
                  </button>
                ) : (
                  <button className="proceed" style={{ marginTop: "20px" }} onClick={() => open()}>
                    Connect Wallet
                  </button>
                )}
              </div>
              <div className="rotation"></div>
              <div className="fullset2 mobiletransition">
                <div className="numbers">2</div>
                <p className="confirm">Destination Chain</p>
                <hr />
                <div className="chainmain">
                  <div className="chainimg">
                    <img
                      src={selectedDestinationChain?.icon}
                      alt={selectedDestinationChain?.name}
                    />
                  </div>
                </div>
                <Dropdown
                  options={chainConfig
                    .filter((f) => {
                      if (selectedToken?.symbol === "USDT") {
                        if (isTestnet) {
                          return f.network !== "Sepolia";
                        } else {
                          return f.network !== "Ethereum";
                        }
                      }
                      return true;
                    })
                    .map((m) => ({ name: m.name, value: m.id.toString() }))}
                  value={selectedDestinationChain?.name?.toString() || ""}
                  onChange={(value) => handleSelectDestinationChain(Number(value))}
                />
                {/* {address ? (
            <button className="proceed" style={{ marginTop: "20px" }} onClick={() => open()}>
              connected to {formatAddress(address)}
            </button>
          ) : (
            <button className="proceed" style={{ marginTop: "20px" }} onClick={() => open()}>
              Connect Wallet
            </button>
          )} */}
              </div>
            </div>
            <div className="fullset2">
              <div className="numbers">3</div>
              <p className="confirm">Confirm Bridging</p>
              <hr />
              <div className="nameblock">
                <h3>From</h3>
                <div className="chain-icon sm border ethereum">
                  <img src={selectedSourceChain?.icon} alt={selectedSourceChain?.name} />
                </div>
              </div>
              <div className={`searbarh flex-item ${isFocused ? "focus-within" : ""}`}>
                <input
                  type="amount"
                  placeholder="Amount"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={tokenAmt}
                  onChange={(e) => setTokenAmt((e.target as any).value)}
                />
                <div
                  className="searchBar-icons"
                  onClick={() =>
                    selectedSourceChain?.tokens[selectedToken?.symbol as any].native
                      ? setTokenAmt(Number(tokenBalanceEth?.value) / 1e18)
                      : setTokenAmt(Number(token1) / 1e18)
                  }
                >
                  MAX
                </div>
                <div>
                  <SelectTokenDropdown
                    selectedToken={selectedToken}
                    setSelectedToken={setSelectedToken}
                    destinationChainId={selectedDestinationChain?.id || 8808}
                  />
                </div>
              </div>
              {selectedSourceChain && (
                <Balance address={address} chainId={chainId} token={selectedToken} />
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <button
                  style={{
                    width: 40,
                    height: 40,
                    marginTop: 20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "none",
                    backgroundColor: "#0c1318",
                    borderRadius: 10,
                  }}
                  onClick={handleRotate}
                >
                  <img src={Rotate} style={{ height: 15 }} />{" "}
                </button>
              </div>
              <div className="nameblock" style={{ marginTop: "20px" }}>
                <h3>To</h3>
                <div className="chain-icon sm border ethereum">
                  <img src={selectedDestinationChain?.icon} alt={selectedDestinationChain?.name} />
                </div>
              </div>
              <div className={`searbarh flex-item ${isFocused ? "focus-within" : ""}`}>
                <input
                  type="amount2"
                  placeholder=""
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={
                    tokenAmt -
                      (selectedDestinationChain?.tokens[selectedToken?.symbol as any]?.fee || 0) >
                    0
                      ? tokenAmt -
                        (selectedDestinationChain?.tokens[selectedToken?.symbol as any]?.fee || 0)
                      : 0
                  }
                />
              </div>
              <Balance
                address={address}
                chainId={selectedDestinationChain?.id || 56}
                token={
                  Config[selectedDestinationChain?.id || 56]?.tokens[selectedToken?.symbol as any]
                }
              />
              <br />
              <div className="reminders">
                <dl className="list">
                  <dt>
                    <p style={{ marginTop: "5px", fontSize: "13px" }}>
                      Reminder:{" "}
                      <span style={{ color: "#fff" }}>
                        Estimated Time of Crosschain Arrival is 10-30 min
                      </span>{" "}
                    </p>
                  </dt>
                  <dt>
                    <p style={{ marginTop: "5px", fontSize: "13px" }}>
                      Minimum swap Amount :
                      <span style={{ color: "#fff" }}>
                        {" "}
                        {selectedToken?.minimumAmount || 0} {selectedToken?.symbol || "W8BIT"}
                      </span>
                    </p>
                  </dt>
                  <dt>
                    <p style={{ marginTop: "5px", fontSize: "13px" }}>
                      Fee :{" "}
                      <span style={{ color: "#fff" }}>
                        {" "}
                        {selectedToken?.fee || 0} {selectedToken?.symbol || "W8BIT"}
                      </span>
                    </p>
                  </dt>
                </dl>
              </div>
              {!selectedToken?.native && !isApproved ? (
                <button
                  className="proceed"
                  onClick={() => approve?.()}
                  disabled={
                    isApproveLoading ||
                    !approve ||
                    tokenAmt < (selectedToken?.minimumAmount || 0) ||
                    Number(token1) / 1e18 < tokenAmt
                  }
                >
                  {!isApproveLoading
                    ? tokenAmt >= (selectedToken?.minimumAmount || 0)
                      ? Number(token1) / 1e18 >= tokenAmt
                        ? "Approve"
                        : "Insufficient Fund!"
                      : `Min Swap ${selectedToken?.minimumAmount || 0} ${
                          selectedToken?.symbol || "w8Bit"
                        }`
                    : "Approving... "}
                </button>
              ) : (
                <button
                  className="proceed"
                  onClick={() => {
                    if (tokenAmt >= (selectedToken?.minimumAmount || 0))
                      selectedToken?.native ? handleETHTransfer() : handleTransfer();
                    else alert(`minimum ${selectedToken?.minimumAmount || 0} Tokens swap!`);
                  }}
                  disabled={
                    isSwapLoading ||
                    tokenAmt < (selectedToken?.minimumAmount || 0) ||
                    Number(selectedToken?.native ? tokenBalanceEth : token1) / 1e18 < tokenAmt
                  }
                >
                  Transfer
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
