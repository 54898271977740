import { w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { Chain, configureChains, createClient } from "wagmi";
import { infuraProvider } from "wagmi/providers/infura";
import { bsc, mainnet, bscTestnet, sepolia } from "wagmi/chains";
// import { bscTestnet, sepolia } from "wagmi/chains";

const EightBitChainTestnet: Chain = {
  id: 3124,
  name: "8Bit Chain Testnet",
  network: "eightbitchaintest", // Add the network property
  nativeCurrency: { name: "8Bit Chain", symbol: "8Bit", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://testnet.8bitblockchain.com"] },
    public: { http: ["https://testnet.8bitblockchain.com"] },
  },
  blockExplorers: {
    default: { name: "8Bit Chain Testnet", url: "https://testnet.8bitscan.app/" },
  },
};

// Define your custom private network configuration
const customNetwork = {
  id: 8088,
  name: "8Bit Chain",
  network: "8Bit",
  nativeCurrency: {
    decimals: 18,
    name: "8Bit",
    symbol: "8Bit",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.8bitscan.com"],
    },
    public: {
      http: ["https://rpc.8bitscan.com"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "8bitScan",
      url: "https://8bitscan.com/",
    },
    default: {
      name: "8bitScan",
      url: "https://8bitscan.com/",
    },
  },
};

export const isTestnet = true;

// const testnets = [sepolia, bscTestnet, EightBitChainTestnet];
const mainnets = [bsc, customNetwork];

export const chains = [...mainnets];
export const projectId = "bf9397e41bf0ab99a492296a2957db54";

const { provider } = configureChains(chains, [
  infuraProvider({ apiKey: "833e6bd4860b416abc509843d803d2f3" }),
  w3mProvider({ projectId }),
]);

export const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  provider,
});
