import React, { useMemo, useState } from "react";
import { useChainId, useSwitchNetwork } from "wagmi";

import "./SelectTokenDropdown.scss";
// import { defaultTokens } from "../../utils/address";
import { IToken } from "../../constants/types";
import { Config } from "../../config";

interface ISelectTokenDropdown {
  selectedToken?: IToken;
  setSelectedToken: React.Dispatch<React.SetStateAction<IToken | undefined>>;
  destinationChainId: number;
}

const SelectTokenDropdown: React.FC<ISelectTokenDropdown> = ({
  selectedToken,
  setSelectedToken,
  destinationChainId,
}) => {
  const chainId = useChainId();
  const [openDropdown, setOpenDropdopwn] = useState(false);

  const tokensList = useMemo(() => {
    const currentToken = Config[chainId];

    return Object.values(currentToken.tokens);
  }, [chainId]);

  const currentSelectedToken = useMemo(() => {
    const currentToken = Config[chainId];

    return currentToken.tokens[selectedToken?.symbol.toUpperCase() || "W8BIT"];
  }, [selectedToken, chainId]);

  return (
    <div className="dropdown-container">
      <div className="d-flex" onClick={() => setOpenDropdopwn((o) => !o)}>
        <img src={currentSelectedToken?.icon} alt="" />
        <p>{currentSelectedToken?.symbol}</p>
      </div>
      {openDropdown && (
        <div className="dropdown-list">
          {tokensList
            .filter((f) => {
              if (destinationChainId === 11155111 || destinationChainId === 1) {
                return f.symbol !== "USDT";
              }
              return true;
            })
            .map((m) => (
              <div
                key={m.UnderlyingContract}
                onClick={() => {
                  setSelectedToken({ ...m });
                  setOpenDropdopwn(false);
                }}
              >
                <img src={m.icon} alt={m.symbol} />
                <p>{m.symbol}</p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default SelectTokenDropdown;
