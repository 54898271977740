import { useAccount, useBalance, useContractRead } from "wagmi";
import tokenABI from "../../utils/abi/tokenABI.json";
import { IToken } from "../../constants/types";

interface IBalanceProps {
  address: `0x${string}` | undefined;
  chainId: number;
  token?: IToken;
}

function Balance(props: IBalanceProps) {
  const { address } = useAccount();
  // console.log(props);
  const {
    data: tokenAmt,
    error,
    isLoading: isLoading,
  } = useContractRead({
    address: (props.token?.UnderlyingContract==='0x0000000000000000000000000000000000000000'?props.token?.TokenContract:props.token?.UnderlyingContract) as any,
    abi: tokenABI,
    chainId: props.chainId,
    functionName: "balanceOf",
    args: [address],
  });
  // console.log(props.address, props.token?.tokenAddress, props.chainId, error);

  const { data: tokenBalanceEth, isLoading: isNativeLoading } = useBalance({
    address: props.address,
    chainId: props.chainId,
  });

  if (props.token?.native) {
    return (
      <p style={{ fontSize: "13px", marginTop: "10px" }}>
        Balance:&nbsp;
        <span style={{ color: "#fff" }}>
          {tokenBalanceEth?.formatted} {props.token?.symbol}
        </span>
      </p>
    );
  }

  return (
    <p style={{ fontSize: "13px", marginTop: "10px" }}>
      Balance:{" "}
      <span style={{ color: "#fff" }}>
        {isLoading
          ? "loading..."
          : isNaN(Number(tokenAmt))
          ? 0
          : (Number(tokenAmt) / 10 ** (props.token?.decimals || 18))?.toString()}{" "}
        {props.token?.symbol}
      </span>
    </p>
  );
}
export default Balance;
